


















import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  setup(_, { root }) {
    const state = reactive({
      loading: false,
      error: false as boolean | number,
    });

    const model = reactive({
      isModuleEnabled: false,
      isImageDisplayed: false,
      isDegreeDisplayed: false,
      isCityDisplayed: false,
      isInstitutionDisplayed: false,
      isCountryDisplayed: false,
    });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`/event/${root.$route.params.id}/lecturer-module`)
        .then(({ data }) => {
          model.isModuleEnabled = data.isModuleEnabled;
          model.isImageDisplayed = data.isImageDisplayed;
          model.isDegreeDisplayed = data.isDegreeDisplayed;
          model.isCityDisplayed = data.isCityDisplayed;
          model.isInstitutionDisplayed = data.isInstitutionDisplayed;
          model.isCountryDisplayed = data.isCountryDisplayed;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          state.loading = false;
        });
    };

    onMounted(fetchData);

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return `${root.$tc("layout.errors.noPermission")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };
    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        isModuleEnabled: model.isModuleEnabled,
        isImageDisplayed: model.isImageDisplayed,
        isDegreeDisplayed: model.isDegreeDisplayed,
        isCityDisplayed: model.isCityDisplayed,
        isInstitutionDisplayed: model.isInstitutionDisplayed,
        isCountryDisplayed: model.isCountryDisplayed,
      };

      state.loading = true;

      axiosInstance
        .put(`/event/${root.$route.params.id}/lecturer-module`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "primary",
            message: root.$tc("layout.misc.saveSuccess"),
          });
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => (state.loading = false));
    };

    return { model, onSubmit };
  },
});
